import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setProjectID } from "../../../../../../Redux/Actions/actions";
import { fetchSlideState } from "./useProjectSlide";

// Create form request
export const createRequest = async (clientId, data, user) => {
  let reqData = {
    ...data,
    activeUserId: user.id,
  };
  const request = await axios.post(
    `/api/create-request/${clientId}/`,
    { ...reqData, requestStatus: "IP" },
    {
      withCredentials: true,
    },
  );

  return request.data;
};

// Custom hook to create request
export default function useCreateRequest(
  clientId,
  setPageNumber,
  user,
  setActiveUserId,
  setTimeStamp,
  setIsLoading,
) {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const create = useMutation({
    mutationFn: (data) => createRequest(clientId, data, user),
    onError: (err, variables, context) => {
      console.log(`Error creating request: `, err);
    },
    onSuccess: async (data) => {
      console.log("data: ", data);
      // set the page number to 1
      const projectId = data.projectId;
      dispatch(setProjectID(projectId));
      setActiveUserId(data.activeUserId);
      setTimeStamp(data.savedAt);
      setIsLoading(false);
      // Invalidate and refetch
      await queryClient.invalidateQueries({
        queryKey: ["currentProject", clientId, projectId],
      });

      setPageNumber(2);
    },
  });

  // Return the mutation
  return create;
}
